<template>
  <div
    class="user-amount"
    :class="{ 'user-amount_disabled': disabled }"
  >
    <span v-if="label" class="user-amount__label">
      {{ label }}
    </span>
    <ui-input
      v-if="!hideInput"
      v-model="inputValue"
      :currency="currency"
      tag="money"
      type="text"
      :placeholder="$t('form.amountPlaceholder')"
      class="user-amount__input"
      clearable
      :disabled="disabled"
      @update:typing="$emit('update:typing', $event)"
    />
    <div v-if="showError" class="user-amount__error">
      {{ error }}
    </div>
    <amount-fee
      v-else-if="inputValue > 0 && !hideFees"
      v-model:feeChecked="checkedFee"
      :currency="currency"
      :fee-amount="calcFee"
      :disabled="disabled"
      :mt="feeMt"
    />
  </div>
</template>

<script>
import Money from '@/class/Money';
import UiInput from './UiInput.vue';
import AmountFee from './AmountFee.vue';

export default {

  components: {
    UiInput,
    AmountFee,
  },

  props: {
    value: {
      type: Number,
      default: 0,
    },
    feeAmount: {
      type: Number,
      default: 0,
    },
    feeChecked: {
      type: Boolean,
      default: true,
    },
    currency: {
      type: Object,
      required: true,
    },
    payoutId: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minTipsAmount: {
      type: Number,
      default: 0.5,
    },
    label: String,
    hideFees: Boolean,
    hideInput: Boolean,
    showErrorAnyway: Boolean,
    feeMt: Boolean,
    apiVersion: {
      type: Number,
      default: 4,
    },
  },

  data() {
    return {
      inputValue: 0,
      checkedFee: this.feeChecked,
    };
  },

  computed: {
    calcFee() {
      if (this.hideFees) {
        return 0;
      }

      return this.$store.getters['fee/getFee'](this.inputValue) || 0;
    },
    feeLoading() {
      if (this.hideFees) {
        return false;
      }

      return this.$store.getters['fee/isLoading'](this.inputValue);
    },
    totalValue() {
      return +parseFloat(this.inputValue + (this.checkedFee ? this.calcFee : 0)).toFixed(2);
    },
    error() {
      if (this.inputValue > 0 && this.inputValue < this.minTipsAmount) {
        return this.$t('form.amountError', {
          money: new Money(this.minTipsAmount, this.currency),
        });
      }

      return null;
    },
    showError() {
      return this.error && (!this.hideFees || this.showErrorAnyway);
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value !== this.totalValue) {
          if (!this.hideInput) {
            value -= this.checkedFee ? this.feeAmount : 0;
          }
          value = +(value).toFixed(2);

          this.inputValue = value;
        }
      },
    },
    feeLoading: {
      immediate: true,
      handler(newValue, oldValue) {
        if (typeof newValue === typeof oldValue) {
          this.$emit('update:feeLoading', newValue);
        }
      },
    },
    calcFee: {
      immediate: true,
      handler(value) {
        if (!this.hideFees) {
          this.$emit('update:feeAmount', value);
        }
      },
    },
    inputValue: {
      immediate: true,
      handler(value) {
        if (!this.hideFees) {
          this.$store.dispatch('fee/calculateFee', {
            payoutId: this.payoutId,
            minTipsAmount: this.minTipsAmount,
            apiVersion: this.apiVersion,
            totalAmount: value,
          });
        }
      },
    },
    checkedFee(value) {
      this.$emit('update:feeChecked', value);
    },
    error: {
      immediate: true,
      handler(error) {
        this.$emit('update:error', error);
      },
    },
    totalValue: {
      immediate: true,
      handler(value) {
        if (this.value !== value && !this.feeLoading) {
          this.$emit('update:value', value);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.user-amount {
  &__label {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    margin-right: 10px;
  }

  &_disabled {
    opacity: 0.25;
  }

  &__input {
    margin-bottom: 8px;
  }

  &__error {
    font-size: 14px;
    color: #e14040;
  }
}
</style>
