<template>
  <ui-button
    v-if="show"
    v-bind="$attrs"
    google-pay
    aria-label="Google pay button"
    @click.prevent="$emit('submit', () => submitPay())"
  />
</template>

<script>
/* eslint-disable no-alert */

import api from '@/api';
import UiButton from '@/components/UiButton.vue';

const CHECKOUT_GOOGLE_GLOBAL = '__ETgooglePayClient';

export default {
  name: 'CheckoutByGoogle',

  components: {
    UiButton,
  },

  props: {
    listPayment: {
      type: Array,
      required: true,
    },
    feeChecked: {
      type: Boolean,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    feeAmount: {
      type: Number,
      required: true,
    },
    groupId: {
      type: String,
    },
    workplacePayoutId: {
      type: String,
      required: true,
    },
    backUrl: {
      type: Object,
      required: true,
    },
    publicKey: {
      type: String,
      required: true,
    },
    totalAmount: {
      type: String,
      required: true,
    },
    merchantId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    show: false,
  }),

  created() {
    if (this.getGooglePayClientCtor()) {
      this.onGooglePayloaded();
    } else {
      const script = document.createElement('script');

      script.async = true;
      script.src = 'https://pay.google.com/gp/p/js/pay.js';
      script.onload = this.onGooglePayloaded;

      document.head.appendChild(script);
    }
  },

  methods: {
    redirect({ status, paymentId }) {
      window.location.href = this.$router.resolve({
        path: `${this.backUrl.locationId}/${status}`,
        query: {
          ...this.backUrl.query,
          checkoutPaymentId: paymentId,
          rate: this.backUrl.rate,
          isGoogleCheckoutPayment: true,
          amount: this.totalAmount,
        },
      }).href;
    },
    async onGooglePayloaded() {
      const googlePayClient = this.getGooglePayClient();

      try {
        const { result } = await googlePayClient.isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [{
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
            },
          }],
        });

        if (result) {
          this.show = true;
        }
      } catch (e) {
        alert(e);
      }
    },
    getGooglePayClientCtor() {
      return window.google?.payments?.api?.PaymentsClient;
    },
    getGooglePayClient() {
      if (!window[CHECKOUT_GOOGLE_GLOBAL]) {
        const GooglePayClient = this.getGooglePayClientCtor();

        window[CHECKOUT_GOOGLE_GLOBAL] = new GooglePayClient({
          environment: process.env.VUE_APP_CHECKOUT_GOOGLE_ENV,
          merchantInfo: {
            merchantName: 'EasyTip',
            merchantId: this.merchantId,
          },
          paymentDataCallbacks: {
            onPaymentAuthorized: this.onPaymentAuthorized,
          },
        });
      }

      return window[CHECKOUT_GOOGLE_GLOBAL];
    },
    async onPaymentAuthorized(paymentData) {
      const googlePaymentToken = paymentData.paymentMethodData.tokenizationData.token;

      try {
        const { data } = await api.createCheckoutGooglePayPayment({
          listPayment: this.listPayment,
          feeChecked: this.feeChecked,
          currency: this.currency,
          amount: +this.totalAmount,
          feeAmount: this.feeAmount,
          workplacePayoutId: this.workplacePayoutId,
          groupId: this.groupId,
          customerCardToken: JSON.parse(googlePaymentToken),
        });

        if (data) {
          this.$nextTick(() => {
            this.redirect({
              status: 'success',
              paymentId: data?.id,
            });
          });

          return {
            transactionState: 'SUCCESS',
          };
        }
      } catch (e) {
        console.log(e);
      }

      return {
        transactionState: 'ERROR',
        error: {
          intent: 'PAYMENT_AUTHORIZATION',
          message: 'Insufficient funds',
          reason: 'PAYMENT_DATA_INVALID',
        },
      };
    },
    async submitPay() {
      const googlePayClient = this.getGooglePayClient();

      try {
        await googlePayClient.loadPaymentData({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [{
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'checkoutltd',
                gatewayMerchantId: this.publicKey,
              },
            },
          }],
          transactionInfo: {
            countryCode: 'US',
            currencyCode: this.currency.toUpperCase(),
            totalPriceStatus: 'FINAL',
            totalPrice: this.totalAmount,
            totalPriceLabel: `Tip for ${this.name}`,
          },
          merchantInfo: {
            merchantName: 'EasyTip',
            merchantId: this.merchantId,
          },
          callbackIntents: ['PAYMENT_AUTHORIZATION'],
        });
      } catch (e) {
        if (e.statusCode === 'CANCELED') {
          console.log(e);
        } else {
          throw e;
        }
      }
    },
  },
};
</script>
