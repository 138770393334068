<template>
  <payermax-by-apple
    v-if="isApplePay"
    v-bind="$attrs"
    v-on="listeners"
    :is-staff="isStaff"
    :list-payment="listPayment"
    :amount="amount"
    :fee-checked="feeChecked"
    :fee-amount="feeAmount"
    :bill-id="billId"
    :is-tips="isTips"
    :staff-payout-id="staffPayoutId"
    :workplace-payout-id="workplacePayoutId"
    :total-amount="totalAmount"
    :amplitude-payment-data="amplitudePaymentData"
    :min-tips-amount="minTipsAmount"
    :disabled="disabled"
    @submit="handleSubmit"
  />
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
} from 'vue';
import PayermaxByApple from '@/components/PayermaxByApple.vue';

const props = defineProps({
  listPayment: Array,
  amount: Number,
  feeChecked: Boolean,
  feeAmount: Number,
  staffPayoutId: String,
  workplacePayoutId: String,
  isStaff: Boolean,
  amplitudePaymentData: Object,
  disabled: Boolean,
  minTipsAmount: Number,
  billId: String,
  billAmount: Number,
  isTips: Boolean,
});

const type = ref(null);

const isApplePay = computed(() => {
  return type.value === 'apple';
});

const checkPaymentMethod = () => {
  if (window.PMdropin.isSupportApplePay()) {
    type.value = 'apple';
  } else {
    type.value = 'google';
  }
};

const loadPayermaxSdk = () => {
  if (window?.PMdropin) {
    checkPaymentMethod();
  } else {
    const script = document.createElement('script');

    script.async = true;
    script.src = 'https://cdn.payermax.com/dropin/js/pmdropin.min.js';
    script.onload = checkPaymentMethod;

    document.head.appendChild(script);
  }
};

onMounted(() => {
  loadPayermaxSdk();
});
</script>
