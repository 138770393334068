<template>
  <div
    class="payment-buttons"
    :class="{ 'payment-buttons--no-offset': noOffset }"
  >
    <checkout-by-pay
      v-if="data.isCheckoutPayment"
      :name="data.name"
      :is-staff="data.isStaff"
      :amplitude-payment-data="data.amplitudePaymentData"
      :list-payment="data.listPayment"
      :fee-checked="data.feeChecked"
      :currency="data.currency"
      :amount="data.amount"
      :fee-amount="data.feeAmount"
      :group-id="data.groupId"
      :staff-payout-id="data.staffPayoutId"
      :workplace-payout-id="data.workplacePayoutId"
      :back-url="data.backUrl"
      :disabled="data.disabled"
      @submit="handleSubmit"
    />
    <payermax-by-pay
      v-else-if="data.isPayermaxPayment"
      :is-staff="data.isStaff"
      :amplitude-payment-data="data.amplitudePaymentData"
      :list-payment="data.listPayment"
      :fee-checked="data.feeChecked"
      :currency="data.currency"
      :amount="data.amount"
      :fee-amount="data.feeAmount"
      :bill-id="data.billId"
      :is-tips="data.isTips"
      :group-id="data.groupId"
      :staff-payout-id="data.staffPayoutId"
      :workplace-payout-id="data.workplacePayoutId"
      :min-tips-amount="data.minTipsAmount"
      :back-url="data.backUrl"
      :disabled="data.disabled"
      @submit="handleSubmit"
    />

    <ryft-payment
      v-else-if="data.isRyftPayment"
      :currency-info="data.currency"
      :list-payment="data.listPayment"
      :fee-checked="data.feeChecked"
      :amount="data.amount"
      :fee-amount="data.feeAmount"
      :workplace-payout-id="data.workplacePayoutId"
      :group-id="data.groupId"
      :is-multi-pay-page="data.isMultiPayPage"
      :success-url="data.successUrl"
      :error-url="data.errorUrl"
      :total-amount="data.totalSum"
      :is-disabled="data.disabled"
    />

    <ui-button
      v-else-if="data.canPay"
      :is-loading="data.payLoading"
      :disabled="data.disabled"
      :apple-pay="data.applePay"
      :google-pay="data.googlePay"
      @click.prevent="handleSubmit('submitPay')"
    />

    <ui-button
      v-if="data.canGiroPay"
      :is-loading="data.giroPayLoading"
      :disabled="data.disabled"
      giro-pay
      @click.prevent="handleSubmit('submitGiroPay')"
    />

    <paypal
      v-if="data.canPayPal"
      :data="data.payPalPaymentData"
      :is-staff="data.isStaff"
      :amplitude-payment-data="data.amplitudePaymentData"
      :disabled="data.disabled"
      @submit="handleSubmit"
    />

    <ui-button
      v-if="showDefaultPayBayCard"
      :is-loading="data.cardLoading"
      :disabled="data.disabled"
      @click.prevent="handlePayByCard"
    >
      {{ $t('form.button.payCard') }}
    </ui-button>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import CheckoutByPay from '@/components/CheckoutByPay.vue';
import PayermaxByPay from '@/components/PayermaxByPay.vue';
import Paypal from '@/components/Paypal.vue';
import UiButton from '@/components/UiButton.vue';
import RyftPayment from '@/components/RyftPayment.vue';

const emit = defineEmits(['submit']);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  noOffset: {
    type: Boolean,
    default: false,
  },
});

const showDefaultPayBayCard = computed(() => {
  return props.data.canPayByCard && !props.data.isRyftPayment;
});

const handleSubmit = (payload) => {
  emit('submit', payload);
};

const handlePayByCard = () => {
  if (props.data.isCheckoutPayment) {
    handleSubmit('submitCheckoutPayByCard');
  } else if (props.data.isPayermaxPayment) {
    handleSubmit('submitPayermaxPayByCard');
  } else {
    handleSubmit('submitPayByCard');
  }
};
</script>

<style lang="scss">
.payment-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 10px;

  &--no-offset {
    gap: 0;
    margin-bottom: 0;
  }
}
</style>
