<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <div class="app" :class="`${locale} ${currency.iso}`">
    <router-view />

    <cookie-banner v-if="!isPrerender" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CookieBanner from '@/components/CookieBanner.vue';

export default {

  components: {
    CookieBanner,
  },

  computed: {
    ...mapState('i18n', [
      'locale',
    ]),
    ...mapState('user', [
      'seokey',
    ]),
    ...mapGetters('user', [
      'currency',
    ]),
    currentYear() {
      return new Date().getFullYear();
    },
    isPrerender() {
      if (typeof navigator === 'undefined') {
        return false;
      }

      return /Prerender/.test(navigator?.userAgent || '');
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

html,
body,
.app, #app {
  height: 100%;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

.app {
  min-height: 100%;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #000;
  display: flex;
  flex-direction: column;

  &__footer {
    background: #282220;
    height: 48px;
    display: flex;
    flex: 1 1 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  &__copyright,
  &__copyright a {
    font-size: 10px;
    color: #fff;
  }

  &__payments {
    img + img {
      margin-left: 17px;
    }
  }
}
</style>
