<template>
  <div class="receipt">
    <ui-checkbox
      v-model="receipted"
      @input="$emit('checked', receipted)"
      class="receipt__checkbox"
      red
      small
    >
      {{ $t('form.receipt') }}
    </ui-checkbox>
    <template v-if="receipted">
      <ui-input
        v-model.trim="email"
        small
        class="receipt__email"
        placeholder="Email"
        :error="errorMessage"
        @input="handleChange"
      />
      <ui-button
        small
        class="receipt__send"
        :disabled="!email || isLoading || errorMessage"
        :is-loading="isLoading"
        @click.prevent="send"
      >
        Send
      </ui-button>
    </template>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import UiCheckbox from './UiCheckbox.vue';
import UiInput from './UiInput.vue';
import UiButton from './UiButton.vue';
import { useI18n } from 'vue-i18n';

export default {

  name: 'UiReceipt',

  setup() {
    const { t } = useI18n();

    const isValid = (value) => {
      const emailRegexp = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i;

      if (!value || !emailRegexp.test(value)) {
        return t('validationErrors.email');
      }

      return true;
    };

    const { errorMessage, handleChange } = useField('comment', isValid);

    return {
      errorMessage,
      handleChange,
    };
  },

  components: {
    UiCheckbox,
    UiInput,
    UiButton,
  },

  props: {
    isLoading: Boolean,
  },

  data: () => ({
    receipted: false,
    email: '',
  }),

  methods: {
    send() {
      this.$emit('send', this.email);
    },
  },
};
</script>

<style lang="scss">
.receipt {

  &__checkbox {
    margin-bottom: 24px;
  }

  &__email {
    margin-top: 24px;
  }

  &__send {
    margin: 24px auto;
    min-width: 128px;

  }
}
</style>
