import { localize } from '@vee-validate/i18n';
import { defineRule, configure } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

import en from '@/locales/en.json';
import de from '@/locales/de.json';
import ae from '@/locales/ae.json';

configure({
  generateMessage: localize({
    en: en.validationErrors,
    de: de.validationErrors,
    ae: ae.validationErrors,
  }),
});

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
