export default {
  "dialogue": {
    "notActive": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashless tipping is temporary unavailable for this venue. Please inform venue manager or administrator."])}
    },
    "paymentFailed": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Payment failed"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])}
    },
    "submitPay": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ve just left a tip"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You’ve just left a tip. Are you sure you want to make another payment for ", _interpolate(_named("total")), "?"])},
      "submit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pay ", _interpolate(_named("total")), " anyway"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "checkoutFailed": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems there's a small issue with the transaction. Could you please try again? We apologize for any inconvenience."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])}
    },
    "payermaxFailed": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems there's a small issue with the transaction. Could you please try again? We apologize for any inconvenience."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])}
    }
  },
  "search": {
    "staffsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a member<br/>of our team you would<br/>like to tip"])},
    "groupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a service<br/>you would like to tip"])},
    "masterLocationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a service<br/>you would like to tip"])},
    "aggregateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a venue<br/>you would like to tip"])},
    "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
    "multipleSpecialistLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select multiple team members"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "chooseOrTipManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose or enter tip manually"])}
  },
  "form": {
    "amountPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the tip amount"])},
    "amountError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tip amount must be at least ", _interpolate(_named("money"))])},
    "fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["+", _interpolate(_named("money")), " platform fee"])},
    "agree": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree with"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      
    ],
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get receipt"])},
    "commentPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a name or a comment"])},
    "button": {
      "onlyFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave review"])},
      "payCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay by card"])}
    },
    "amountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
    "billLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total bill"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "rate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate the place"])},
    "titleByRate": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrible"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent"])},
      
    ]
  },
  "reviews": {
    "titleBad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What went wrong?"])},
    "titleGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What did you like the most?"])}
  },
  "modal": {
    "fee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why am I being asked to pay a platform fee?"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTip is a cashless tipping platform created for hospitality, beauty and service workers. We are a startup and have to charge a small fee for the use of our platform."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The good news is, if you are a customer leaving a tip and agree to cover the platform fee, 100% of your tip is sent to the recipient."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for using our platform and supporting thousands of hard working hospitality and services staff. Let’s make tipping fairer and simpler together."])},
        
      ],
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The receipt has been sent to your email"])}
    },
    "sorry": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your review"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry to hear your experience wasn’t great. May we contact you in order to make things better next time?"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or phone number"])},
      "button": {
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
        "notNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not now"])}
      }
    },
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Payment failed"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something wrong :("])}
    },
    "terms": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it!"])}
    },
    "privacy": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it!"])}
    },
    "maxTipAmount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip limit exceeded"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sorry, but the tip amount cannot exceed ", _interpolate(_named("money")), ". Please enter a tip amount within the limit."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])}
    }
  },
  "social": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like tipping with EasyTip?<br>Follow us"])}
  },
  "currency": {
    "gbp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["£"])},
    "usd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
    "aed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AED"])},
    "eur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
    "chf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHF"])}
  },
  "cookieBanner": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easytip.net website uses cookies. By continuing to browse the site you are agreeing to our use of cookies. For more detail see our "])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie policy"])}
  },
  "cookiePolicy": {
    "weAreKeenly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are keenly aware of the trust you place in us, and our responsibility to protect your privacy. As part of this responsibility, we let you know what information we collect using cookies when you use our websites, why we collect it and how we use it to improve your experience."])},
    "introduction": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTRODUCTION"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Tip Ltd (“we” or “us” or “our”) may use cookies, web beacons, tracking pixels and other tracking technologies, when you visit our website <a href=\"www.easytip.net\">www.easytip.net</a> and subdomains, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”) to help customize the Site and improve your experience."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["his Cookie Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Cookie Policy. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to receive specific notice of each such change or modification."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted."])},
        
      ]
    },
    "useOfCookies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USE OF COOKIES"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A \"cookie\" is a string of information which assigns you a unique identifier that we store on your computer. Your browser then provides that unique identifier to use each time you submit a query to the Site. We use cookies on the Site to, among other things, keep track of services you have used, record registration information, record your user preferences, keep you logged into the Site, facilitate purchase procedures, record actions and events on the Site and track the pages you visit."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None of our cookies used for our sites can be used to identify you personally. They are just used to help make the site better for you. To learn more about cookies and how to manage them, visit <a href=\"http://aboutcookies.org\">http://aboutcookies.org/</a> or <a href=\"https://www.allaboutcookies.org\">https://www.allaboutcookies.org/</a>."])},
        
      ]
    },
    "typesOfCookies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TYPES OF COOKIES"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following types of cookies may be used when you visit the Site:"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessary cookies"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are cookies that are strictly necessary for the operation of a website and for such aspects as authentication, fraud prevention and detection, security and functionality. Without these cookies, this website won’t work properly. Accordingly, we are not asking you for your specific consent for those cookies. For all other cookies your informed consent is required."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics Cookies"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics cookies monitor how users reached the Site, and how they interact with and move around once on the Site. These cookies let us know what features on the Site are working the best and what features on the Site can be improved."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalization Cookies"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalization cookies are used to recognize repeat visitors to the Site. We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time you visit the Site."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising Cookies"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising cookies are placed on your computer by advertisers and ad servers in order to display advertisements that are most likely to be of interest to you. These cookies allow advertisers and ad servers to gather information about your visits to the Site and other websites, alternate the ads sent to a specific computer, and track how often an ad has been viewed and by whom. These cookies are linked to a computer and do not gather any personal information about you."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third-Party Cookies"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third-party cookies may be place on your computer when you visit the Site by companies that run certain services we offer. These cookies allow the third parties to gather and track certain information about you. These cookies can be manually disabled in your browser."])},
        
      ]
    },
    "CookiesUsedByQrTip": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COOKIES USED BY QR Tip"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])}
    },
    "ControlOfCookies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTROL OF COOKIES"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most browsers are set to accept cookies by default. However, you can remove or reject cookies in your browser’s settings. Please be aware that such action could affect the availability and functionality of the Site."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information on how to control cookies, check your browser or device’s settings for how you can control or reject cookies, or visit the following links:"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition, you may opt-out of some third-party cookies through the <a href=\"optout.networkadvertising.org/#!/\">Network Advertising Initiative’s Opt-Out Tool</a>."])},
        
      ]
    },
    "OtherTrackingTechnologies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTHER TRACKING TECHNOLOGIES"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the Site to help customize the Site and improve your experience. A “web beacon” or “pixel tag” is tiny object or image embedded in a web page or email. They are used to track the number of users who have visited particular pages and viewed emails, and acquire other statistical data. They collect only a limited set of data, such as a cookie number, time and date of page or email view, and a description of the page or email on which they reside. Web beacons and pixel tags cannot be declined. However, you can limit their use by controlling the cookies that interact with them."])},
        
      ]
    },
    "privacyPolicy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIVACY POLICY"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information about how we use information collected by cookies and other tracking technologies, please refer to our <a href=\"https://www.easytip.net/privacy-policy\">Privacy Policy</a> posted on the Site. This Cookie Policy is part of and is incorporated into our Privacy Policy. By using the Site, you agree to be bound by this Cookie Policy and our Privacy Policy."])},
        
      ]
    },
    "contactUs": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have questions or comments about this Cookie Policy, please contact us at:"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Tip Ltd<br/>Unit 3, 8 Greenock Road,<br/>London W3 8DU<br/>United Kingdom<br/><a href=\"mailto:info", "@", "easytip.net\">info", "@", "easytip.net</a><br/>"])},
        
      ]
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it!"])}
  },
  "validationErrors": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be a valid email"])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comment must contain at least ", _interpolate(_named("length")), " characters"])}
  },
  "review": {
    "ATMOSPHERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmosphere"])},
    "BATHROOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathroom"])},
    "CARE_PRODUCTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Care products"])},
    "CLEANLINESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleanliness"])},
    "COMFORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comfort"])},
    "DESIGN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "DINNING_AREA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinning area"])},
    "DRINKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drinks"])},
    "ENTERTAINMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertainment"])},
    "FOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food"])},
    "FRIENDLINESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friendliness"])},
    "HOOKAH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hookah"])},
    "HOSPITALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospitality"])},
    "KIDS_FRIENDLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kids friendly"])},
    "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "MENU_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu choice"])},
    "MUSIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
    "PET_FRIENDLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet friendly"])},
    "PRICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "PUNCTUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctuality"])},
    "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "STAFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
    "TABLE_PLAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table plan"])},
    "VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "CHECK_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in"])}
  }
}