<template>
  <ui-bottom-sheet
    v-bind="$attrs"
    v-on="$listeners"
    #default="{ close }"
  >
    <div class="bill-info-details">
      <div class="bill-info-details__title">
        Order
      </div>

      <ul class="bill-info-details__list">
        <li
          v-for="item in items"
          :key="item.name"
          class="bill-info-details__item"
        >
          <div class="bill-info-details__name">
            {{ item.name }}
          </div>

          <div class="bill-info-details__info">
            <div v-if="showServiceCount(item.count)" class="bill-info-details__info-count">
              x {{ item.count }}
            </div>

            <div class="bill-info-details__info-total">
              {{ item.total }}
            </div>
          </div>
        </li>
      </ul>

      <div class="bill-info-details__divider" />

      <div class="bill-info-details__amount">
        <div class="bill-info-details__amount-label">
          Order amount:
        </div>

        <div class="bill-info-details__amount-value">
          {{ billAmount }}
        </div>
      </div>

      <bill-discount
        v-if="billDiscount"
        :data="billDiscount"
        :currency="currency"
      />

      <bill-tax-list
        class="bill-info-details__taxes"
        :items="billTaxList"
      />

      <ui-button
        class="bill-info-details__button"
        @click="close"
      >
        Ok
      </ui-button>
    </div>
  </ui-bottom-sheet>
</template>

<script setup>
import UiBottomSheet from '@/components/UiBottomSheet.vue';
import UiButton from '@/components/UiButton.vue';
import BillTaxList from './BillTaxList.vue';
import BillDiscount from './BillDiscount.vue';

const props = defineProps({
  currency: Object,
  items: Object,
  billAmount: Object,
  billTaxList: Array,
  billDiscount: Object,
});

const showServiceCount = (count) => {
  return count > 1;
};
</script>
